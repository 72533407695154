<template>
    <swarm-widget :title="'Change Password'">
      <template slot="body">
        <form>
        <input type="password" placeholder="Current password" class="input" autocomplete="on" v-model="currentPassword">
            <input type="password" placeholder="New password" class="input" autocomplete="on" v-model="newPassword">
            <input type="password" placeholder="Confirm new password" class="input"  autocomplete="on" v-model="confirmPassword">
          <p class="error" v-if="error">{{ error }}</p>
          <button class="btn-block-primary">
              <button :class="{'disabled' : !match}" @click="updatePassword" class='btn-block-primary'>Update Password</button>
            </button>
        </form>
      </template>
    </swarm-widget>
</template>

<script>
import swarmWidget from '@/components/general/widget'
export default {
  name: 'manage-password-widget',
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },
  components: {
    swarmWidget
  },
  methods: {
    updatePassword() {
      const result = {
        oldPassword: this.currentPassword,
        newPassword: this.newPassword
      };
      this.$store.dispatch('changeAuthUserPassword', JSON.stringify(result));
      this.confirmPassword = '',
          this.currentPassword = '',
          this.newPassword = ''
    }
  },
  computed: {
    match() {
      if(this.newPassword.length && this.confirmPassword.length && this.currentPassword.length) {
        return this.newPassword === this.confirmPassword;
      } else {
        return false;
      }
    },
    error() {
      if(this.$store.getters.userAuth.changePasswordError)
        return 'Update password error, please try again.';
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  color: red;
}

</style>
