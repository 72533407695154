<template>
  <swarm-widget :title="'Account Info'">
    <template slot="body">
      <form>
      <div class="user-detail form">
        <div class="form-row flex">
        </div>
        <div class="form-row">
            <header class="label">
                Email
            </header>
            <input class="input sm" type="email" placeholder="mail@address.com" v-model="email">
        </div>
      <div class="form-row flex">
            <div class="form-group">
              <header class="label">
                Username
              </header>
              <input class="input sm" type="text" placeholder="Username" v-model="username">
            </div>
            <div class="form-row flex">
              <div class="form-group">
              <header class="label">
                Password
              </header>
              <input type="password" placeholder="Enter password to update values" class="input sm" autocomplete="on" v-model="currentPassword">
                </div>
            </div>
        <button class="btn-block-primary">
          <button :class="{'disabled' : !match}" @click="updateAccount" class='btn-block-primary'>Update Account</button>
        </button>
      </div>
      <div class="ctrl"><p class="error" v-if="error">{{ error }}</p></div>
    </div>
      </form>
    </template>
  </swarm-widget>
</template>

<script>
import swarmWidget from "@/components/general/widget";

export default {
  name: 'user_detail_form',
components: {
  swarmWidget
},
data() {
    return {
      currentPassword: '',
      email: this.$store.getters.userInfo.email,
      username:this.$store.getters.userInfo.username,
    }
  },
  mounted() {
    this.$store.commit('setUserInfoError',null)
  },
  methods: {
  updateAccount() {
    const result = {
      email: this.email,
      username: this.username,
      password: this.currentPassword
    };
    this.$store.dispatch('updateAuthUser', JSON.stringify(result));
  }
},computed: {
    match() {
      if(this.currentPassword.length > 3) {
        return true;
      } else {
        return false;
      }
    },
    error() {
      if(this.$store.getters.userInfoError)
        return 'Update account error, please try again.';
    }
  }
}
</script>

<style lang="scss" scoped>
    .user-detail {
        background: #fff;
        border-radius: 2px;
        border: 1px solid rgba(17,18,21,0.1);
        padding: 20px 16px;
        margin-bottom: 16px;
    }

    .error {
      color: red;
    }

</style>
