<template>
  <div class="account-settings">
      <main class="main">
        <user-detail></user-detail>
            <subscription></subscription>
        </main>
        <aside class="sidebar">
            <manage-password></manage-password>
        </aside>
    </div>
</template>

<script>
import managePassword from '@/components/user/account-settings/manage-password'
import subscription from '@/components/user/account-settings/subscription'
import userDetail from '@/components/user/account-settings/user-detail'

export default {
    name: 'account-settings',
    components: {
        managePassword,
        subscription,
        userDetail
    },
  computed: {
    error() {
      if(this.$store.getters.userAuth.error)
        return 'Login error, please try again.';
    }
  },
  mounted() {
    this.$store.getters.userAuth.changePasswordError = null;
  }
}
</script>

<style lang="scss" scoped>
    .account-settings {
        max-width: 965px;
        display: flex;
        @media(max-width: 1000px) {
            flex-direction: column;
        }
        .main {
            flex:1;
        }
        .sidebar {
            flex: 332px 0 0;
            margin-left: 32px;
            @media(max-width: 1000px) {
                margin-left: 0;
            }
        }
    }
</style>
